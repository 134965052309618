<template>
    <div class="collection">
        <div>
            <h1 class="title">职位收藏列表</h1>
        </div>
        <div>
        <el-table :data="tableData" style="width: 100%" v-loading="loading">
            
            <el-table-column prop="job_name" label="职位名称" show-overflow-tooltip  width= '230' min-width= '200'>
            </el-table-column>

            <el-table-column prop="allocation" label="职位类型">
            </el-table-column>

            <el-table-column prop="job_num" label="招聘人数" align= 'center'>
                <template slot-scope="scope">
                    {{ scope.row.job_num }}人
                </template>
            </el-table-column>

            <el-table-column prop="salary" label="薪资待遇" align= 'center'>
                <template slot-scope="scope">
                    <div v-if="scope.row.min_salary_range ==0 || scope.row.max_salary_range ==0">
                        面议
                    </div>
                    <div v-else>
                        {{ scope.row.min_salary_range }}-{{scope.row.max_salary_range }}k
                    </div>               
                </template>
            </el-table-column>

            <el-table-column prop="status" label="职位状态" align= 'center'>
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.status == "1" ? "正常" : scope.row.status == "0" ? "职位已关闭" : "完成" }}
                    </span>
                </template>
            </el-table-column>

            <el-table-column prop="add_times" label="发布时间">
            </el-table-column>

            <el-table-column prop="" label="操作" align = 'center' width= '160'>
                <template slot-scope="scope">
                <el-button
                size="mini"
                type="primary"
                @click="jobInfo(scope.row.id)">详情</el-button>
                <el-button
                size="mini"
                type="danger"
                @click="jobDelete(scope.row.id)">移除</el-button>
            </template>
            </el-table-column>           
        </el-table>
        <el-row class="l-pagination">
            <Pagination :page.sync="page" :limit.sync="limit" :total="count" @getList="getCollection()" />
        </el-row>
        </div>
    </div>
</template>

<script>
import {getCollectionApi,delJobCollApi} from '@/api/collection'
import Pagination from '@/components/Pagination/index.vue'

    export default{
        
        components: {
            Pagination
        },

        data(){
            return{
                loading: false,
                page:1,
                limit:5,
                count: 0,
                tableData: [],
            }
        },

        created(){
            this.getCollection()
        },      

        methods:{
            async getCollection(){
                this.loading = true
                const {
                    data: res
                } = await getCollectionApi(this.page,this.limit)
                if (res.status !== 10000) {
                    this.loading = false
                    return this.$message.error(res.message)                   
                }
                this.tableData = res.result.list
                this.count = res.result.count
                this.loading = false
            },

            jobInfo(id){
                this.$router.push(`/pc/colleJobInfo?id=${id}`)
            },            

            jobDelete (id) {
                this.$confirm('是否移除?', '移除收藏', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delJobCollApi(id).then(res => {
                        if (res.data.status != 10000) {
                            this.$message.error(res.data.message) 
                            return
                        }
                        this.$message.success('移除成功')
                        this.getCollection()
                    })
                }).catch(() => {
                    this.$message.info('已取消');
                });
            }
        }
    }

</script>


<style lang="less" scoped>
    .title{
        text-align: center;
        font-weight: normal;
        line-height: 40px;
        border-bottom: 1px solid #e4e7ed;
        font-size: 20px;
    }
    .l-pagination{
        padding-top: 40px;
        text-align: right;
    }
    .el-button--primary{
        color: #fff;
        background-color: #409eff;
        border-color: #409eff;
        :hover{
            border-color: #66b1ff;
            color: #fff;
        }
    }
</style>
